import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('ContentArea', {
    staticClass: "content-area justify-center text-center",
    class: [_vm.$vuetify.breakpoint.smAndUp ? 'pa-8' : 'pa-4']
  }, [_c('div', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "476px"
    }
  }, [_c('div', {
    staticClass: "d-inline-block rounded-circle primary pa-2"
  }, [_c('IconsTree', {
    staticClass: "white--text",
    attrs: {
      "width": "32",
      "height": "32"
    }
  })], 1), _vm._v(" "), _c('h5', {
    staticClass: "text-center my-4"
  }, [_vm._v(_vm._s(_vm.$t('wow.donationSuccess.title')))]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 secondary-text--text mb-1"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('wow.donationSuccess.desc1')) + "\n        "), _c('br'), _vm._v("\n        " + _vm._s(_vm.$t('wow.donationSuccess.desc2')) + "\n        "), _c('br', {
    staticClass: "hidden-xs-only"
  }), _vm._v("\n        " + _vm._s(_vm.$t('wow.donationSuccess.desc3')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption secondary-text--text mb-6"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('wow.donationSuccess.aboutDonationLinkPrefix')) + "\n        "), _c('LinkText', {
    attrs: {
      "to": "https://sendwow.notion.site/082d0ff571e94064a357a73bfb59cea8",
      "text": _vm.$t('wow.donationSuccess.aboutGiftLink')
    }
  }), _vm._v("\n        " + _vm._s(_vm.$t('wow.donationSuccess.or')) + "\n        "), _c('LinkText', {
    attrs: {
      "to": "https://sendwow.notion.site/f9c2b24683e54b0f8a027aced0b5959d",
      "text": _vm.$t('wow.donationSuccess.aboutDonationLink')
    }
  }), _vm._v("\n        " + _vm._s(_vm.$t('wow.donationSuccess.aboutDonationLinkSuffix')) + "\n      ")], 1), _vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _vm.$t('wow.donationSuccess.back')
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: "/wow/".concat(_vm.$route.params.id)
        });
      }
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };